import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb";
import MagazineBlockBig from "../components/MagazineBlock/MagazineBlockBig";

import { Heading } from "../style/Heading";
import { Container, Flex, Wrapper } from "../style/Grid";

import { URLS } from "../const/const";

const MagazinCategory = ({
  data: {
    wp: {
      category: {
        name,
        slug,
        posts: { nodes: posts },
      },
    },
  },
  uri,
  pageContext: { hasNextPage, pageNumber },
}) => {
  require("moment/locale/cs");

  const breadcrumbs = [
    {
      label: "Magazín",
      link: URLS.blog,
    },
    {
      label: name,
      link: uri,
    },
  ];

  return (
    <Layout>
      <SEO title={`Magazín - ${name}`} />
      <Header variant="light" />
      <Wrapper>
        <Container flexDirection="column">
          <Flex flexDirection="column" mb={2} mt={16}>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Heading as={"h1"} variant={"h1"} uppercase>
              Magazín
            </Heading>
          </Flex>
        </Container>
      </Wrapper>
      <Wrapper>
        <Container flexDirection="column">
          <Flex
            flexDirection={["column", null, "row"]}
            flexWrap={"wrap"}
            justifyContent={["flex-start", null, "space-between"]}
            mb={110}
          >
            <MagazineBlockBig
              posts={posts}
              category={{ name: name }}
              showFirst
            />
          </Flex>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default MagazinCategory;

export const query = graphql`
  query GET_CATEGORY($id: ID!) {
    wp {
      category(id: $id) {
        id
        name
        slug
        uri
        posts(first: 100) {
          nodes {
            id
            title
            uri
            slug
            date
            content: excerpt
            featuredImage {
              node {
                sourceUrl(size: MEDIUM)
              }
            }
          }
        }
      }
    }
  }
`;
