import React from "react";
import { Heading } from "../../style/Heading";
import { Flex } from "../../style/Grid";
import { MagazineBlockImage } from "../../style/MagazineBlock/MagazineBlockImage";
import Link from "../Link";
import { Image } from "../../style/Image";
import moment from "moment";
import { MagazineBlockItemDescription, MagazineBlockItemDetails, MagazineBlockItemDetailsItem } from "../../style/MagazineBlock";
import { Link as FakeLink } from "../../style/Link";
import Icon from "../../style/Icon";
import { Button } from "../../style/Button";

import { htmlDecode } from "../../utils";

const MagazineBlockBig = ({ posts, hasNextPage, pageNumber, category, title, pathPrefix = "/magazin", externalLink = null, pageType = null }) => (
  <Flex width={category || pageType === "iwp" ? 1 : [1, null, null, "65%", "71.25%"]} flexDirection="column">
    <Heading as="h3" fontSize={[18, null, 3]} lineHeight={["21px", null, "23px"]} fontWeight={600} color={"headlinesSecondary"} mb={26}>
      {title ? title : `Nejnovější články ${category ? `v kategorii ${category.name}` : ""}`}
    </Heading>
    
    
    {posts &&
      posts.map(
        (post, index) =>
          (pageType === "iwp" || category || index !== 0) && (
            <Flex
              flexDirection={["column", "row", "row"]}
              key={post.id}
              width={"100%"}
              minWidth={"230px"}
              mb={40}
              style={{
                "content-visibility": "auto",
                "contain-intrinsic-size": "200px",
              }}
            >
              <MagazineBlockImage
                width={["100%", "285px"]}
                height={["160px", "160px"]}
                borderRadius={1}
                image={post?.featuredImage?.node?.sourceUrl ?? require("../../assets/images/placeholder/test-image-9.jpg").default}
                loading={"lazy"}
              >
                <Image
                  src={post?.featuredImage?.node?.sourceUrl ?? require("../../assets/images/placeholder/test-image-9.jpg").default}
                  borderRadius={1}
                  border={2}
                  width={["100%", "285px"]}
                  height={["160px", "160px"]}
                  opacity="0"
                  loading={"lazy"}
                />
              </MagazineBlockImage>
              <Flex ml={[0, 20]} mt={[20, 0]} flexDirection="column" alignItems={"flex-start"}>
                <Link variant={"noUnderline"} href={`${pathPrefix}${post.uri}`}>
                  <Heading as="h4" fontSize={3} fontWeight={400} lineHeight="23px" color={"link"} my={1}>
                    {htmlDecode(post.title)}
                  </Heading>
                </Link>
                <MagazineBlockItemDetails as={"ul"}>
                  <MagazineBlockItemDetailsItem as={"li"}>
                    {moment(post.date)
                      .locale("cs")
                      .format("D. MMMM YYYY")}
                  </MagazineBlockItemDetailsItem>

                  {post.categories &&
                    post.categories.nodes &&
                    post.categories.nodes.map(category => (
                      <MagazineBlockItemDetailsItem key={category.id} as={"li"}>
                        {category.name}
                      </MagazineBlockItemDetailsItem>
                    ))}
                </MagazineBlockItemDetails>
                <MagazineBlockItemDescription dangerouslySetInnerHTML={{ __html: post.content }} />
                <FakeLink variant="smallBorderTop" mt={30} as={Link} href={`${pathPrefix}${post.uri}`}>
                  celý článek
                  <Icon mb={"-1px"} ml={1} icon="icon-next" size={10} color="cta" />
                </FakeLink>
              </Flex>
            </Flex>
          )
      )}
    {hasNextPage && (
      <Flex alignItems={"center"} justifyContent={"center"} mb={[57, null, null, 0]}>
        <Button as={Link} fontSize={12} href={`${pathPrefix}/${pageNumber + 1}`}>
          Načíst další články
        </Button>
      </Flex>
    )}
    {externalLink && (
      <Flex alignItems={"center"} justifyContent={"center"} mb={[57, null, null, 0]}>
        <Button variant="secondary" as={Link} fontSize={12} href={`${externalLink}`}>
          Další články
        </Button>
      </Flex>
    )}
  </Flex>
);

export default MagazineBlockBig;
